import { useSearchParams } from "@remix-run/react";
import { clsx, type ClassValue } from "clsx";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const isPathActive = (
  path: string | null | undefined,
  currentPath: string,
  basePath: string = "/"
) => {
  // also matches if the current location is a sub path
  if (path === basePath && currentPath != basePath) {
    // every path is a sub path of the basePath so we catch that case here
    return false;
  }
  if (!path) {
    return false;
  }
  const currentPathArray = currentPath
    .split("/")
    .filter((pathSegment) => pathSegment !== "");
  const targetPathArray = path
    .split("/")
    .filter((pathSegment) => pathSegment !== "");

  if (currentPathArray.length < targetPathArray.length) {
    return false;
  }

  return targetPathArray.every(
    (pathSegment, index) => pathSegment === currentPathArray[index]
  );
};

export const getEmbedYoutubeUrl = (url: string) => {
  if (url.includes("embed")) {
    // already an embed url
    return url;
  }
  if (!url || !url.includes("v=")) {
    // not a valid youtube url
    return url;
  }
  const videoId = url.split("v=")[1];
  return `https://www.youtube-nocookie.com/embed/${videoId}`;
};

export const getEmbedYoutubeLiveUrl = (url: string) => {
  if (url.includes("embed")) {
    // already an embed url
    return url;
  }
  if (!url || !url.includes("v=")) {
    // not a valid youtube url
    return url;
  }
  const videoId = url.split("v=")[1];
  return `https://www.youtube.com/embed/${videoId}`;
};

// https://github.com/kentcdodds/kentcdodds.com/blob/main/app/utils/misc.tsx#L296-L323
export function useUpdateQueryStringValueWithoutNavigation(
  queryKey: string,
  queryValue: string
) {
  useEffect(() => {
    const currentSearchParams = new URLSearchParams(window.location.search);
    const oldQuery = currentSearchParams.get(queryKey) ?? "";
    if (queryValue === oldQuery) return;

    if (queryValue) {
      currentSearchParams.set(queryKey, queryValue);
    } else {
      currentSearchParams.delete(queryKey);
    }
    const newUrl = [window.location.pathname, currentSearchParams.toString()]
      .filter(Boolean)
      .join("?");
    // alright, let's talk about this...
    // Normally with remix, you'd update the params via useSearchParams from react-router-dom
    // and updating the search params will trigger the search to update for you.
    // However, it also triggers a navigation to the new url, which will trigger
    // the loader to run which we do not want because all our data is already
    // on the client and we're just doing client-side filtering of data we
    // already have. So we manually call `window.history.pushState` to avoid
    // the router from triggering the loader.
    window.history.replaceState(null, "", newUrl);
  }, [queryKey, queryValue]);
}

export function useSearchParamState(
  key: string,
  defaultValue: string
): [string, (value: string) => void] {
  const [searchParams] = useSearchParams();
  const [value, setValue] = useState(() => {
    return searchParams.get(key) ?? defaultValue;
  });
  useUpdateQueryStringValueWithoutNavigation(key, value);

  return [value, setValue];
}
